import { css } from 'lit';

const stylesBannerVerticalLandscape = css`
:host {
  --container-width: 0px; /* Default Value  */
  --title-font-size: calc(var(--container-width)*28/352);
  --title-line-height: calc(var(--container-width)*32/352);
  --dynamic-1: calc(var(--container-width)*1.5/352);
  --dynamic-2: calc(var(--container-width)*2/352);
  --dynamic-3: calc(var(--container-width)*3/352);
  --dynamic-4: calc(var(--container-width)*4/352);
  --dynamic-7: calc(var(--container-width)*7/352);
  --dynamic-8: calc(var(--container-width)*8/352);
  --dynamic-10: calc(var(--container-width)*10/352);
  --dynamic-11: calc(var(--container-width)*11/352);
  --dynamic-12: calc(var(--container-width)*12/352);
  --dynamic-16: calc(var(--container-width)*16.5/352);
  --dynamic-24: calc(var(--container-width)*24/352);
  --dynamic-26: calc(var(--container-width)*26/352);
  --dynamic-28: calc(var(--container-width)*28.5/352);
  --dynamic-32: calc(var(--container-width)*32/352);
  --dynamic-48: calc(var(--container-width)*48/352);
  --dynamic-60: calc(var(--container-width)*60/352);
  --dynamic-66: calc(var(--container-width)*66/352);
  --dynamic-82: calc(var(--container-width)*82/352);
  --dynamic-18n: calc(var(--container-width)*-18/352);
  --dynamic-15n: calc(var(--container-width)*-15/352);
}

.content-target {
  text-decoration: none;
  cursor: pointer;
}

.adn-container-landscape {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--dynamic-4);
  aspect-ratio: 352/381;

  .adn-container-image {
    height: 48.3%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: var(--dynamic-4);
    border-top-right-radius: var(--dynamic-4);
  }

  .adn-base-image {
    aspect-ratio: 1.91/1; 
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 50%;
  }
  
  .adn-content-text {
    padding: var(--dynamic-66) var(--dynamic-32) var(--dynamic-48) var(--dynamic-32);
  }
  
  .adn-content-text.no-logo-no-volanta {
    padding: var(--dynamic-48) var(--dynamic-32) var(--dynamic-66) var(--dynamic-32);  
  }
}

.adn-container-texts {
  border-bottom-left-radius: var(--dynamic-4);
  border-bottom-right-radius: var(--dynamic-4);
}

.adn-container-logo {
  position: relative;
}

.position-logo {
  position: absolute;
  margin-left: var(--dynamic-32);
  transform: translate(0, -46%);
}

.adn-logo {
  background-color: white;
  width: var(--dynamic-82);
  height: var(--dynamic-60);
  padding: var(--dynamic-12);
  box-sizing: border-box;
  border-radius: var(--dynamic-2);
  object-fit: contain;
}

.adn-logo:first-child {
  margin-right: var(--dynamic-4);
}

.adn-text-without-volanta {
  margin: 0;
}

.adn-text-volanta {
  font-size: var(--dynamic-10);
  font-weight: 600;
  letter-spacing: var(--dynamic-3);
  margin: 0 0 var(--dynamic-8) 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adn-text-title {
  font-size: var(--dynamic-24);
  line-height: var(--dynamic-26);
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
}

.with-volanta, .no-volanta {
  margin-top:var(--dynamic-18n);
}

.adn-cta {
  display: flex;
  margin: var(--dynamic-8) 0 0 0;
}

.adn-text-cta {
  font-size: var(--dynamic-12);
  margin: 0;
  font-weight:600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adn-cta-chevron {
  width: var(--dynamic-8);
  margin: var(--dynamic-1) 0 0 var(--dynamic-7);
  display: flex;
}

.adn-text-legal {
  font-size: var(--dynamic-11);
  font-weight: 400;
  margin: var(--dynamic-8) 0 var(--dynamic-15n) 0;
  line-height: var(--dynamic-16);
  white-space: pre-wrap;
  word-wrap: break-word;
}
`;

export default stylesBannerVerticalLandscape;
