import { customElement } from 'lit/decorators.js';
import stylesBannerVerticalLandscape from './styles/adn-banner-vertical-landscape-image';
import AdnBannerVerticalGenericForAspectRatio from './adn-banner-vertical-generic-for-aspect-ratio';
import { resizeObserver } from './utils/resize-observer';

@customElement('adn-banner-vertical-landscape-image')
export default class AdnBannerVerticalLandscapeImage extends AdnBannerVerticalGenericForAspectRatio {
  static styles = [stylesBannerVerticalLandscape];

  protected firstUpdated(): void {
    super.firstUpdated();

    const container = this.shadowRoot?.querySelector('.adn-container-landscape') as HTMLElement;
    this.style.setProperty('--container-width', `${container.offsetWidth}px`);

    const resizeObserverInstance = resizeObserver(this.style);
    resizeObserverInstance.observe(container);
  }

  constructor() {
    super('landscape');
  }
}
