const PATH_ADN = '/advertising/adn';
const SOURCE = 'template';
const ACTION_TYPE = 'cleanAndSend';
const STATUS_ERROR = 'error';

const trackEvent = (trackEventData: TrackEventData) => {
  if (window.melidata) {
    window.melidata(ACTION_TYPE, 'event', {
      path: PATH_ADN,
      event_data: trackEventData,
    });
  }
};

const hasPrintAlreadySent = (printUrl: string): boolean => {
  try {
    const savedPrints: string[] = JSON.parse(sessionStorage.getItem('adn-print-mem') || '[]');
    return savedPrints.includes(printUrl);
  } catch (e) {
    console.log('error getting from storage: ', e);
    return false;
  }
};

export const trackPrintError = (reason: string, url?: string) => {
  const event_data = { status: STATUS_ERROR, source: SOURCE, reason };
  trackEvent(url ? { ...event_data, url } : event_data);
};

export const trackCall = ({ url }: TrackUrl) => {
  if (hasPrintAlreadySent(url)) {
    console.log('print already sent', url);
    return;
  }

  fetch(url, { method: 'GET' })
    .then((response: Response) => {
      if (![200, 204].includes(response.status)) {
        throw new Error(`Fetch failed with status code ${response.status}`);
      }

      try {
        const savedUrls = JSON.parse(sessionStorage.getItem('adn-print-mem') || '[]');
        sessionStorage.setItem(
          'adn-print-mem',
          JSON.stringify([...savedUrls.slice(-4), url]),
        );
      } catch (e) {
        console.log('error saving to storage: ', e);
      }
    })
    .catch((error: TypeError) => {
      trackPrintError(error?.message, url);
    });
};
